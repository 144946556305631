import create from 'zustand';
import { Filter } from '../../components/common/Filters/model/filters.model';

interface StoreFilters {
  filters: Filter;
  order: string;
  doTheSearch: boolean;
  filterOpen: boolean;
  setFilters: (filter: Partial<Filter>) => void;
  clearFilter: () => void;
  setOrder: (order: string) => void;
  setDoTheSearch: (doTheSearch?: boolean) => void;
  setOpenFilter: (doTheSearch?: boolean) => void;
}

const useStoreFilter = create<StoreFilters>((set) => ({
  filters: {},
  order: '',
  doTheSearch: false,
  filterOpen: false,
  setFilters: (filter: Partial<Filter>): void => {
    set((state) => ({
      ...state,
      filters: {
        // ...get().filters,
        ...filter,
      },
    }));
  },
  setOrder: (order: string): void => {
    set((state) => ({
      ...state,
      order,
    }));
  },
  setDoTheSearch: (doTheSearch: boolean): void => {
    set((state) => ({
      ...state,
      doTheSearch,
    }));
  },
  setOpenFilter: (openFiler: boolean): void => {
    set((state) => ({
      ...state,
      openFiler,
    }));
  },
  clearFilter: (): void => {
    set((state) => ({
      ...state,
      filters: {},
    }));
  },
}));

export default useStoreFilter;
