import { get, set, remove } from 'local-storage';

export enum NameStorage {
  basket = 'basket',
  areaOfInterest = 'areaOfInterest',
  areaOfInterestName = 'areaOfInterestName',
  myurl = 'myurl',
  noSubscriptionToNewsletter = 'noSubscriptionToNewsletter',
  addressBasket = 'addressBasket',
}

export const isClient = (): boolean => typeof window !== 'undefined';

export const getDataFromStorage = <T>(nameStorage: keyof typeof NameStorage): T => {
  if (!isClient()) {
    return null;
  }

  const localStorageData = get<T>(nameStorage);
  const conditionLocalStorageNull =
    !localStorageData ||
    (typeof localStorageData === 'string' &&
      (localStorageData === 'null' || localStorageData === 'undefined'));

  return conditionLocalStorageNull ? null : localStorageData;
};

export const setDataInStorage = <T>(nameStorage: keyof typeof NameStorage, value: T): void => {
  set(nameStorage, value);
};

export const removeDataInStorage = <T>(nameStorage: keyof typeof NameStorage): void => {
  remove(nameStorage);
};
