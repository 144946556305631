import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import constants from '../utils/constants';
import { useGetAreaNameLazyQuery } from '../graphqlTypes';
import useStoreFilter from '../reducer/filter/storeFilters';
import { Filter } from '../components/common/Filters/model/filters.model';
import useScrollBarPage from './useScrollbarPage';

interface ReturnFiltersAndOrder {
  changeFilters: (
    filter: { prop: string; value },
    pushData?: boolean,
    doTheSearch?: boolean,
    keepInitialValueIfExist?: boolean,
  ) => void;
  resetFilter: () => void;
  resetOrder: () => void;
  changeOrder: (order: string, keepInitialValueIfExist?: boolean, doTheSearch?: boolean) => void;
  getOrder: () => string;
  getDoTheSearch: () => boolean;
  getFilters: () => Filter;
  keepFiltersAndOrder: () => number;
  changeDoTheSearch: (doTheSearch?: boolean) => void;
  doTheSearch: boolean;
  changeFilterArea: (
    id: number,
    doTheSearch?: boolean,
    keepInitialValueIfExist?: boolean,
    pushData?: boolean,
  ) => void;
  filter: Filter;
  existFilter: () => boolean;
  order: string;
  filterOpen: boolean;
  setOpenFilter: (doTheSearch?: boolean) => void;
}

const useFiltersAndOrder = (): ReturnFiltersAndOrder => {
  const [
    orderState,
    filters,
    doTheSearch,
    filterOpen,
    setOrder,
    setFilters,
    clearFilter,
    setDoTheSearch,
    setOpenFilter,
  ] = useStoreFilter((stateStore) => [
    stateStore.order,
    stateStore.filters,
    stateStore.doTheSearch,
    stateStore.filterOpen,
    stateStore.setOrder,
    stateStore.setFilters,
    stateStore.clearFilter,
    stateStore.setDoTheSearch,
    stateStore.setOpenFilter,
  ]);
  const { asPath } = useRouter();
  const { isTheActualPageSave, pageScrollPosition } = useScrollBarPage();
  const areaOption = useRef<{
    doTheSearch: boolean;
    keepInitialValueIfExist: boolean;
    pushData: boolean;
  }>(null);

  const [getAreaNameLazyQuery, { data: getAreaName }] = useGetAreaNameLazyQuery({
    fetchPolicy: 'cache-first',
  });

  const getDoTheSearch = (): boolean => {
    return doTheSearch;
  };

  const changeDoTheSearch = (doTheSearchData?: boolean): void => {
    setDoTheSearch(doTheSearchData);
  };

  const changeFilters = (
    filter: { prop: string; value },
    pushData?: boolean,
    doTheSearchData?: boolean,
    keepInitialValueIfExist?: boolean,
  ): void => {
    changeDoTheSearch(doTheSearchData);

    if (Object.keys(filters).length && keepInitialValueIfExist) return;

    const { ...currentFilter } = filters;
    if (filter.prop === 'Tags') {
      if (!currentFilter?.[filter.prop]) {
        currentFilter[filter.prop] = [];
      }

      if (!pushData) {
        currentFilter[filter.prop] = Object.keys(filter.value).length > 0 ? filter.value : null;
      } else {
        for (let i = 0; i < filter.value.length; i += 1) {
          const { prop, items } = filter.value[i];
          let itemsCurrent = currentFilter[filter.prop].find((x) => x.prop === prop);

          let exists = true;
          for (let j = 0; j < items.length; j += 1) {
            const currentItemInput = items[j];
            if (!itemsCurrent) {
              exists = false;
              itemsCurrent = {
                prop,
                items: [],
              };
            }

            const index = itemsCurrent.items.findIndex((x) => x === currentItemInput);

            if (index === -1) {
              itemsCurrent.items.push(currentItemInput);
            }

            if (!exists) {
              currentFilter[filter.prop].push(itemsCurrent);
            }
          }
        }
      }
    } else if (filter.value) {
      currentFilter[filter.prop] = filter.value.length > 0 ? filter.value : null;
    }

    if (currentFilter[filter.prop] === null) {
      delete currentFilter[filter.prop];
    }
    setFilters({ ...currentFilter });
  };

  useEffect(() => {
    const area = getAreaName?.getAreaName;
    if (!area) return;

    const filter = filters && JSON.parse(JSON.stringify(filters));
    let filterAreas = filter?.['Áreas'];

    if (filterAreas) {
      const index = filterAreas.indexOf(area?.id?.toString());
      if (index === -1) {
        filterAreas.push(area?.id?.toString());
      } else {
        filterAreas.splice(index, 1);
      }
    } else {
      filterAreas = [];
      filterAreas.push(area?.id?.toString());
    }

    changeFilters(
      { prop: 'Áreas', value: filterAreas },
      areaOption.current?.pushData,
      areaOption.current?.doTheSearch,
      areaOption.current?.keepInitialValueIfExist,
    );

    areaOption.current = null;
  }, [getAreaName?.getAreaName]);

  const changeFilterArea = (
    id: number,
    doTheSearchData?: boolean,
    keepInitialValueIfExist?: boolean,
    pushData?: boolean,
  ): void => {
    areaOption.current = {
      doTheSearch: doTheSearchData,
      keepInitialValueIfExist,
      pushData,
    };

    getAreaNameLazyQuery({ variables: { id } });
  };

  const changeOrder = (
    order: string,
    keepInitialValueIfExist?: boolean,
    doTheSearchData?: boolean,
  ): void => {
    changeDoTheSearch(doTheSearchData);
    if (orderState && keepInitialValueIfExist) return;
    setOrder(order);
  };

  const resetFilter = (): void => {
    clearFilter();

    setTimeout(() => {
      changeDoTheSearch(false);
    }, 700);
  };

  const resetOrder = (): void => {
    if (orderState !== '') setOrder('');
  };

  const getOrder = (): string => {
    return orderState;
  };

  const getFilters = (): Filter => {
    return filters;
  };

  const keepFiltersAndOrder = (): number => {
    if (isTheActualPageSave()) {
      return 1;
    }

    if (
      constants.allProductCategoriesFull.includes(asPath.replace('/', '')) &&
      pageScrollPosition?.pageName &&
      !constants.allProductCategoriesFull.includes(pageScrollPosition?.pageName?.replace('/', ''))
    ) {
      return 2;
    }

    return 0;
  };

  const existFilter = (): boolean => {
    const sizeCategoryFilter = filters?.Categorías?.length;
    const sizeTagsFilter = filters?.Tags?.length;
    const sizeAreaFilter = filters?.Áreas?.length;
    const sizePrecioFilter = filters?.Precio?.length;
    const sizeIdiomaFilter = filters?.Precio?.length;

    if (
      sizeCategoryFilter ||
      sizeTagsFilter ||
      sizeAreaFilter ||
      sizePrecioFilter ||
      sizeIdiomaFilter
    ) {
      return true;
    }

    return false;
  };

  return {
    changeFilters,
    resetFilter,
    resetOrder,
    changeOrder,
    getOrder,
    getDoTheSearch,
    getFilters,
    keepFiltersAndOrder,
    changeDoTheSearch,
    changeFilterArea,
    existFilter,
    setOpenFilter,
    doTheSearch,
    filter: filters,
    order: orderState,
    filterOpen,
  };
};

export default useFiltersAndOrder;
