import create from 'zustand';
import { positionValues } from 'react-custom-scrollbars';

export interface PageScrollPosition {
  pageName: string;
  Y: number;
}

export type PositionValues = positionValues;

interface StoreScrollbar {
  pageScrollPosition: PageScrollPosition;
  scrollbarValues: PositionValues;
  saveScrollPosition: (pageName: string, scrollVal?: number) => void;
  setScrollbarValues: (scrollbarValues: positionValues) => void;
  setScrollPosition: (pageScrollPosition: PageScrollPosition) => void;
}

const useStoreScrollbar = create<StoreScrollbar>((set) => ({
  pageScrollPosition: null,
  scrollbarValues: null,
  saveScrollPosition: (pageName: string, scrollVal?: number): void => {
    set((prevState) => ({
      ...prevState,
      pageScrollPosition: {
        pageName,
        Y: scrollVal || prevState?.scrollbarValues?.scrollTop,
      },
    }));
  },
  setScrollbarValues: (scrollbarValues: PositionValues): void => {
    set((prevState) => ({ ...prevState, scrollbarValues }));
  },
  setScrollPosition: (pageScrollPosition: PageScrollPosition): void => {
    set((prevState) => ({ ...prevState, pageScrollPosition }));
  },
}));

export default useStoreScrollbar;
