import { useRouter } from 'next/router';
import shallow from 'zustand/shallow';
import useStoreScrollbar, {
  PageScrollPosition,
  PositionValues,
} from '../reducer/scrollbar/storeScrollbar';

interface ScrollBarHookReturn {
  pageScrollPosition: PageScrollPosition;
  scrollbarValues: PositionValues;
  savePageAndScrollPosition: () => void;
  setScrollbarValues: (scrollbarValues: PositionValues) => void;
  isTheActualPageSave: () => boolean;
  setScrollPosition: (pageScrollPosition: PageScrollPosition) => void;
}

const useScrollBarPage = (): ScrollBarHookReturn => {
  const { asPath } = useRouter();
  const [
    pageScrollPosition,
    scrollbarValues,
    saveScrollPosition,
    setScrollbarValues,
    setScrollPosition,
  ] = useStoreScrollbar(
    (store) => [
      store.pageScrollPosition,
      store.scrollbarValues,
      store.saveScrollPosition,
      store.setScrollbarValues,
      store.setScrollPosition,
    ],
    shallow,
  );

  const savePageAndScrollPosition = (): void => {
    saveScrollPosition(asPath);
  };

  const isTheActualPageSave = (): boolean => {
    return pageScrollPosition?.pageName === asPath;
  };

  return {
    pageScrollPosition,
    scrollbarValues,
    savePageAndScrollPosition,
    setScrollbarValues,
    isTheActualPageSave,
    setScrollPosition,
  };
};

export default useScrollBarPage;
